import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';
import './footer.css';
import '../../pages/sign-in/index';
import '../../pages/sign-up/index';
import '../../style/App.css';

function Footer(props) {
	
	return (

	<footer className="footer">

		<div className="footer-1">
			<ol className="footer-list-1">
				<h3></h3>
				<li>
				</li> 
				<li>
				</li> 
				<li>	
				</li> 
				<li>
				</li> 
			</ol>		
		 	<ol className="footer-list-2">
		 		<h3>Certificado Pro</h3>
		 		<li>
					<Link to='/sign-in'  className="footer-link">Entrar</Link> 
				</li>
		 		<li>
					<Link to='/sign-up' className="footer-link">Criar conta</Link> 
				</li>
		 		<li>
		 			<Link to="/sign-in" className="footer-link">Começar</Link> 
		 		</li>
		 	</ol>
		</div>

		<div className="footer-2">
			<h4>Copyright - 2024 - Certificado Pro</h4>	
		</div>	
	</footer>
	);
}

export default Footer;